<template>
  <div>
    <h1>Sandbox</h1>
    <grid-layout :layout="layout" :row-height="30" :colNum="12" :vertical-compact="false">
      <grid-item v-for="item in layout"
          @move="handleMove"
          @moved="handleMoved"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          class="sandbox-grid-item">
        {{ item.i }}
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';

export default {
  name: 'Sandbox',
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      moving: false,
      running: true,
      layout: [
        {
          x: 0,
          y: 0,
          w: 3,
          h: 10,
          i: 0,
        },
        {
          x: 0,
          y: 6,
          w: 3,
          h: 6,
          i: 1,
        },
        {
          x: 3,
          y: 0,
          w: 3,
          h: 6,
          i: 2,
        },
        {
          x: 6,
          y: 6,
          w: 3,
          h: 3,
          i: 3,
        },
        {
          x: 3,
          y: 6,
          w: 3,
          h: 3,
          i: 4,
        },
        {
          x: 3,
          y: 9,
          w: 3,
          h: 3,
          i: 5,
        },
      ],
      oldLayout: [],
    };
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition, false);
    this.running = true;
    this.tick();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.updateMousePosition, false);
    this.running = false;
  },
  methods: {
    tick() {
      if (this.running) {
        if (this.moving) {
          if (this.mouseY > window.innerHeight - 30) {
            scrollBy(0, 5);
          } else if (this.mouseY < 30) {
            scrollBy(0, -5);
          }
        }
        setTimeout(this.tick, 1.0 / 60.0);
      }
    },
    updateMousePosition(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    handleMove() {
      if (!this.moving) {
        this.oldLayout = this.deepCopy(this.layout);
      }
      this.moving = true;
    },
    handleMoved(movedItemIndex) {
      const newLayout = [this.layout.find(x => x.i === movedItemIndex)];
      for (let i = 0; i < this.layout.length; i++) {
        const oldItem = this.oldLayout[i];
        if (oldItem.i === movedItemIndex) {
          continue;
        }

        let collision = false;
        for (const newItem of newLayout.filter(x => x.i !== movedItemIndex && x.i !== oldItem.i)) {
          const xOverlap = oldItem.x >= newItem.x && oldItem.x < newItem.x + newItem.w
              || oldItem.x + oldItem.w > newItem.x && oldItem.x + oldItem.w < newItem.x + newItem.w;
          const yOverlap = oldItem.y >= newItem.y && oldItem.y < newItem.y + newItem.h
              || oldItem.y + oldItem.h > newItem.y && oldItem.y + oldItem.h < newItem.y + newItem.h;
          if (xOverlap && yOverlap) {
            collision = true;
            break;
          }
        }
        if (collision) {
          newLayout.push(this.layout[i]);
        } else {
          newLayout.push(this.oldLayout[i]);
        }
      }
      this.layout = newLayout;
      this.moving = false;
    },
  },
};
</script>

<style scoped>
.sandbox-grid-item {
  touch-action: none;
  background-color: lightgray;
}
</style>